package ru.raysmith.mkefooddelivery

import kotlinx.serialization.Serializable

@Serializable
data class GdeData(val value: String, val page: String, val title: String)

object Gde {
    val foyer = GdeData("Фудкорт экспо фое", "foyer", "Фудкорт")
    val foyer1 = GdeData("Фойе 1 этажа", "foyer1", "Зона выдачи 1 этаж")
    val foyer2 = GdeData("Фойе 2 этажа", "foyer2", "Зона выдачи 2 этаж")
    val letnik = GdeData("Летник на улице", "letnik", "Летник")
    val dvvs = GdeData("ДВВС", "dvvs", "ДВВС")

    val urals = GdeData("НаУралеЕсть", "urals", "НаУралеЕсть")

    val unknown = GdeData("", "", "")
    
    fun values() = listOf(foyer, foyer1, foyer2, letnik, dvvs, urals)
}


//@Serializable
//data class RK7Query(
//    @XmlSerialName("RK7Command") val cmd: ICommand,
//)