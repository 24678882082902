package ru.raysmith.mkefooddelivery

import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
data class ScreenData(
    val gde: GdeData,
    val cooking: MutableList<OrderData>,
    val done: MutableList<OrderData>,
) {
    companion object {
        fun empty(gde: GdeData) = ScreenData(gde, mutableListOf(), mutableListOf())
    }
}

@Serializable
data class OrderData(
    val id: Int,
    val number: String,
    val date: LocalDateTime,
    @Transient val gde: GdeData? = null
) {
    override fun equals(other: Any?): Boolean {
        return number == (other as? OrderData)?.number
    }
    
    override fun hashCode(): Int {
        var result = number.hashCode()
        result = 31 * result + date.hashCode()
        return result
    }
}