import androidx.compose.runtime.*
import androidx.compose.ui.text.AnnotatedString
import io.ktor.client.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.websocket.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import io.ktor.websocket.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import kotlinx.serialization.decodeFromString
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.renderComposable
import org.w3c.dom.HTMLBodyElement
import ru.raysmith.mkefooddelivery.Gde
import ru.raysmith.mkefooddelivery.GdeData
import ru.raysmith.mkefooddelivery.ScreenData

val json = kotlinx.serialization.json.Json {
//	prettyPrint = true
	isLenient = true
	ignoreUnknownKeys = true
}

val client = HttpClient {
	install(ContentNegotiation) {
		json(json)
	}
	
	install(WebSockets) {
//		pingInterval = 30_000
	}
}

fun main() {
	renderComposable("root") {
		val coroutineScope = rememberCoroutineScope()
		var data: ScreenData by remember {
			mutableStateOf(ScreenData.empty(Gde.unknown))
		}
		
		val url = Url(document.URL)
		val segment = url.pathSegments.dropWhile { it.isEmpty() }.joinToString("/")
		if (segment.startsWith("urals")) {
			(document.body as? HTMLBodyElement)?.classList?.add("urals-body")
		}
		val domain = url.host

		coroutineScope.launch {
			while (isActive) {
				try {
					client.webSocket("wss://$domain/screen/$segment") {
//					client.webSocket("ws://localhost:8081/screen/$segment") {
						while (isActive) {
							data = (incoming.receive() as? Frame.Text)?.let {
								json.decodeFromString<ScreenData>(it.readText())
							} ?: continue
							println("screen data updated: ${data.cooking.size}/${data.done.size}")
						}
					}
				} catch (e: Exception) {
					e.printStackTrace()
					delay(1000)
					continue
				}
			}
			client.close()
		}
		
		Div(attrs = {
			style {
				height(100.px)
				alignItems(AlignItems.Center)
				justifyContent(JustifyContent.Center)
				display(DisplayStyle.Flex)
				fontSize(48.px)
				fontFamily("Font1")
				color(Color("#333"))
			}
		}) {
			Text(Gde.values().find { it.page == segment }?.title ?: "")
		}
		
		Div(attrs = {
			classes("container")
		}) {
			Div(attrs = {
				classes("column")
			}) {
				H2 {
					Text("Заказ")
				}
				Ul {
					data.cooking.forEach {
						Li({
							attr("key", it.id.toString())
						}) {

							Text(it.number)
						}
					}
				}
			}
			Div(attrs = {
				classes("column")
			}) {
				H2 {
					Text("Готово")
				}
				Ul {
					data.done.forEach {
						Li({
							attr("key", it.id.toString())
						}) {
							Text(it.number)
						}
					}
				}
			}
		}
	}
}
